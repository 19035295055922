import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	USERDATA,
	SIGNIN,
	SIGNOUT,
	SIGNUP} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess} from "../actions/Auth";

import JWTServices from 'services/JwtAuthService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(JWTServices.login, {email : email , password : password});
			if (user.status) {
				localStorage.setItem(AUTH_TOKEN, user.data.access_token);
				
				localStorage.setItem(USERDATA, JSON.stringify(user.data.user));
				yield put(authenticated(user.data.user.id));
				
			} else {
				yield put(showAuthMessage(user.message));
			}
		} catch (err) {
		
			yield put(showAuthMessage("Kullanıcı Bilgileriniz Hatalı"));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(JWTServices.login);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(JWTServices.login, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}


export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
  ]);
}
