import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}



JwtAuthService.Getstatus = function () {
	return fetch({
		url: '/statuses',
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	});
}

JwtAuthService.GetSmsTemplates = function () {
	return fetch({
		url: '/smstemplates',
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	});
}



JwtAuthService.GetLead = function (slug="") {
	return fetch({
		url: '/leads/'+slug,
		method: 'get',
		type: 'json',
		headers: {
      		'public-request': 'true'
    	}
	})
}

JwtAuthService.updateLead = function (data , id) {
	return fetch({
		url: '/leads/'+id,
		method: 'put',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

JwtAuthService.convertLead = function (data , id) {
	return fetch({
		url: '/leads/convert/'+id,
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}



JwtAuthService.sendSms = function (data) {
	return fetch({
		url: '/sendsms',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}


JwtAuthService.GetUsers = function () {
	return fetch({
		url: '/auth/users',
		method: 'get',
		type: 'json',
		headers: {
      		'public-request': 'true'
    	}
	})
}

JwtAuthService.getReports = function () {
	return fetch({
		url: '/reports/dashboard',
		method: 'get',
		type: 'json',
		headers: {
      		'public-request': 'true'
    	}
	})
}


JwtAuthService.addReminder = function (data) {
	return fetch({
		url: '/leads/addReminder',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

JwtAuthService.CompleteReminder = function (id) {
	return fetch({
		url: '/leads/completeReminder/'+id,
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	})
}


JwtAuthService.getCategorys = function () {
	return fetch({
		url: '/category',
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	})
}


JwtAuthService.getBrands = function (id) {
	return fetch({
		url: '/brands/'+id,
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	})
}


JwtAuthService.getProducts= function (category,brand) {
	return fetch({
		url: '/products/'+category+'/'+brand,
		method: 'get',
		headers: {
      'public-request': 'true'
    }
	})
}
export default JwtAuthService